import React from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ArrowLeftIcon from "../assets/icons/arrow.svg"
const FormSuccessPage = () => (
  <Layout pageName="form-success">
    <SEO title="Thank you!" />
    <section className="section is-hero">
      <div className="container">
        <h1 className="has-text-centered title is-2 has-margin-bottom-6">
          Thank you for subscribing to our newsletter!{" "}
        </h1>
        <div className="columns is-centered">
          <div className="column is-two-fifths">
            <div className="is-card" style={{ padding: 64 }}>
              <p className="is-size-5 has-margin-bottom-3">
                Your subscription has been confirmed. You've been added to our
                list and will hear from us soon.
              </p>
              <Link to="/blog" className="has-text-primary is-flex is-vcentered">
                <span
                  className="icon is-small has-margin-right-3"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <ArrowLeftIcon />
                </span>
                Back to the blog
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FormSuccessPage
